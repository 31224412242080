<template>
  <div class="demo">
    <div id="echartOrder" class="chartBar"></div>
  </div>
</template>
<script>
import CountTo from "vue-count-to";
import { GET_SEVEN_PMS } from "@/api";
// import { isPhone } from "@/api/common";
import {
  title_Font,
  title2_Font,
  x_Font,
  y_Font,
  w_Font,
  h_Font,
  line_h_Font,
  line_Font,
} from "./font";

export default {
  components: {
    CountTo,
  },
  data() {
    return {
     
      placeOrderName: [
        "美团",
        "去哪儿",
        "艺龙",
        "飞猪旅行",
        "携程",
        "途家",
        "蚂蚁短租",
      ],
      placeOrder: [
        {
          name: "艺龙",
          value: 356,
        },
        {
          name: "飞猪旅行",
          value: 288,
        },
        {
          name: "携程",
          value: 72,
        },
        {
          name: "美团",
          value: 1099,
        },
        {
          name: "途家",
          value: 23,
        },

        {
          name: "去哪儿",
          value: 619,
        },
        {
          name: "蚂蚁短租",
          value: 10,
        },
      ],
    };
  },
  computed: {
    color() {
      return this.$store.state.color;
    },
  },
  mounted() {
    this.echartOrder = this.$echarts.init(
      document.getElementById("echartOrder")
    );
    window.addEventListener("resize", () => {
      this.echartOrder.resize();
    });
    this.getOrderType();
    this.placeOrderInter = null;
    this.placeOrderInter = setInterval(() => {
      this.getOrderType();
    }, 3600000);
  },

  beforeDestroy() {
    clearInterval(this.placeOrderInter);
  },
  methods: {
    remFontSize(rem) {
      var fontSize = document.documentElement.style.fontSize;
      // .style.fontSize;
      // alert(fontSize+'---------fontSize');
      return Math.floor(rem * fontSize.replace("px", ""));
    },
    getOptions(data) {
      // let flag = isPhone();
      return {
        title: {
          text: "下单渠道",
          textStyle: {
            align: "left", //字体居中
            color: "#fff", //字体颜色
            fontSize: title2_Font(), //字体大小
          },
        },
        tooltip: {
          trigger: "item",
          textStyle: {
            color: "#fff",
            lineHeight: h_Font(),
            fontSize: x_Font(),
          },
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        color: this.color,
        //  ['#2ec7c9','#b6a2de','#5ab1ef','#ffb980','#37a2da','#32c5e9','#9fe6b8','#ffdb5c','#ff9f7f','#fb7293','#8378ea'],
        legend: {
          bottom: x_Font(),
          itemWidth: w_Font(),
          itemHeight: h_Font(),
          textStyle: {
            color: "#fff",
            lineHeight: line_h_Font(),
            fontSize: x_Font(),
          },
          data: this.placeOrderName,
        },
        series: [
          {
            name: "下单渠道",
            type: "pie",
            // radius: "50%",
            // radius: ["35%", "50%"],
            // center: ["50%", "45%"],
              radius: ["25%", "38%"],
            center: ["50%", "45%"],
            label: {
              show: true,
              fontSize: x_Font(),
            },
             labelLine:{  
              length:h_Font(),
            }, 
            data: this.placeOrder,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    // 获取七日内的pms 出租率情况
    async getOrderType(data = {}) {
      // ["dayRented", "dayPMS", "day"].forEach((item) => {
      //   this.$set(this.sevenPms, item, []);
      // });
      // let res = await GET_SEVEN_PMS(data);
      // res.data.data.forEach((item) => {
      //   this.sevenPms.dayRented.unshift(item.deviceRentedCount);
      //   this.sevenPms.dayPMS.unshift(item.occupancyRate);
      //   this.sevenPms.day.unshift(item.createTime);
      // });
      // // this.sevenPms.day.pop();
      // // this.devicePMS.pms = this.sevenPms.dayPMS.pop();
      // // this.devicePMS.device = this.sevenPms.dayRented.pop();
      // setTimeout(() => {
      //   this.initDevicePMS = this.devicePMS;
      // }, 2000);
      this.initLine();
      // this.sevenPms = res.data.data
    },
    initLine() {
      this.echartOrder.setOption(this.getOptions(this.sevenPms));
    },
    resize() {
      this.echartOrder.resize();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
.demo {
  width: 100%;
  height: 100%;
}
</style>